import { configureStore } from '@reduxjs/toolkit';
import {
  PLATFORM_FEATURE_KEY,
  globalErrorHandlerMiddleware,
  platformReducers,
} from '@clatter/platform';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { DOCUMENTS_FEATURE_KEY, documentsReducer } from './documents.slice';
import { PAGEIMAGES_FEATURE_KEY, pageImagesReducer } from './pageImages.slice';
import { TEMPLATES_FEATURE_KEY, templatesReducer } from './templates.slice';
import { USERS_FEATURE_KEY, usersReducer } from "./users.slice";

export const storeConfig = {
  reducer: {
    [DOCUMENTS_FEATURE_KEY]: documentsReducer,
    [PAGEIMAGES_FEATURE_KEY]: pageImagesReducer,
    [TEMPLATES_FEATURE_KEY]: templatesReducer,
    [PLATFORM_FEATURE_KEY]: platformReducers,
    [USERS_FEATURE_KEY]: usersReducer,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
};

export const store = configureStore(storeConfig);
